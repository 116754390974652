import Alpine from "alpinejs";

export default (() => {;

    function wait(delay) {
        var start = new Date().getTime();
        while (new Date().getTime() < start + delay);
    }

    return {
        init() {
            console.log("StripComponent");
        },

        mediaLoaded: false,
        initialized : false,
        soundEnabled : true,

        get is_loading() {
            return Alpine.store('loading') == true;
        }

    }
})
