import './style.scss';

import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect'

import LoaderComponent from './js/components/Loader';
import StripComponent from './js/components/Strip';
import Homepage from "./js/components/Homepage";

// For local testing only
if (window.location.hostname == 'localhost') window.Alpine = Alpine;

Alpine.data('LoaderComponent', LoaderComponent);
Alpine.data('StripComponent', StripComponent);
Alpine.data('Homepage', Homepage);

Alpine.plugin(intersect);
Alpine.start();
