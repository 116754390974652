import Alpine from "alpinejs";

export default (() => {
    ;

    function wait(delay) {
        var start = new Date().getTime();
        while (new Date().getTime() < start + delay) ;
    }

    function loadImage(src) {
        return new Promise(function (resolve) {

            var img = new Image();

            img.onload = _ => {
                resolve()
            };
            img.onerror = _ => {
                resolve()
            };
            img.src = src;
        });
    }


    Alpine.store('loading', true)

    return {
        init() {

            var pageLoadPromise = new Promise(function (resolve) {
                window.addEventListener('load', (evt) => {
                    evt.preventDefault()
                    resolve();
                })
            })

            var pageShowPromise = new Promise(function (resolve) {
                window.addEventListener('pageshow', (evt) => {
                    evt.preventDefault()
                    resolve();
                });
            })

            var imagesToLoad = [pageLoadPromise, pageShowPromise];
            document.querySelectorAll('img, video, audio').forEach((img) => {
                imagesToLoad.push(loadImage(img.src));
            })

            Promise
                .all(imagesToLoad)
                .then(function () {

                    setTimeout(() => {
                        Alpine.store('loading', false)

                    }, 500);

                });


        },

        get is_loading() {
            return Alpine.store('loading') == true;
        },

        overlay: {

            [':class']() {
                return {
                    'opacity-0 pointer-events-none': Alpine.store('loading') == false,
                    'opacity-100 pointer-events-all': Alpine.store('loading') == true,
                }
            },

        }


    }
})
